
<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">

            <div class="topback">
            <headtip @showDialog="showPartner"></headtip>
            </div>
            <div class="layer2">
              <div class="title1">基于压裂参数的EUR预测及参数反推平台</div>
              <div class="title2">通过先进的数据分析技术，结合压裂参数，为您分析预计采收储量（EUR）。不仅如此，系统还能根据EUR值反向推算关键参数，实现历史记录的深度挖掘和相关性分析，帮助您优化油藏管理和提高采收效率。</div>
              <div class="titlle3" @click="clickConsult('地质工程云平台')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake1">
            <div class="heighLight">功能介绍</div>

            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/frackIcon1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">压裂相关性分析</div>
                    <div class="Title2">
                      利用先进的深度学习技术，对压裂EUR预测相关的工程参数及地质参数进行分析，准确分析不同参数对于最终可采储量的影响，从而为施工过程中的调整做出指导。

                    </div>

                  </div>


                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/frackIcon2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">EUR预测</div>
                    <div class="Title2">
                      通过机器学习技术，将对EUR造成影响的参数进行深度挖掘，得出不同参数和EUR之间的联系，进而为用户提供EUR分析。
                      其中，参数反推是利用机器学习技术，设定数值，根据测定的现场地质情况，选定任意1-6个工程参数进行反推参数数值。
                    </div>
                  </div>


                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/frackIcon3.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">预测EUR历史记录</div>
                    <div class="Title2">
                      用户可查看已手动保存的数据分析结果，并根据任务场景、数据所属油田、所属区块、创建时间进行查询。
                    </div>
                  </div>


                </div>


              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">功能体验</div>
            <div class="heighLight1">基于压裂参数的EUR预测及参数反推平台功能涵盖<span style="font-weight: bold">压裂相关分析</span>、<span style="font-weight: bold">EUR预测</span>，您可在<span style="font-weight: bold;color: red;">下方进行功能切换，免费体验！</span> <br>若您有合作意向和产品咨询，请<span style="color: #FF6400;cursor: pointer" @click="clickConsult('油气通')">联系我们</span>。 </div>
            <div class="grid">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="压裂相关性分析" name="first">
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div style="display: flex">
                          <div class="Title1">
                            1. 数据来源：
                          </div>
                          <div class="Title2">
                            测、录井数据;&nbsp;
                            地震数据;&nbsp;
                            地质参数;&nbsp;
                            压裂实时采集数据&nbsp;
                          </div>
                        </div>
                        <div>
                          <div class="Title1">
                            2. 操作流程：<span class="Title2" > 点击【上传数据】按钮，进入数据上传页面，下载【模拟数据样例】（您也可对表中数据进行修改），点击【数据上传】，上传【模拟数据样例表】，点击应用【数据分析】按钮，查看分析结果。
                 </span>
                          </div>
                          <div class="Title2" style="display: flex">
                          </div>
                        </div>
                        <div style="display: flex">
                          <!-- <div class="Title1">
                            *&nbsp;  注意事项：
                          </div>
                          <div class="Title2">
                            模型选择对数据分析的准确度至关重要，选择与您上传数据所在区域相关的模型可提高数据分析准确率。
                          </div> -->
                        </div>



                      </div>
                      <div ref="iframeContainer" class="iframeStyle">
                        <iframe :src="iframeUrl1" frameborder="0" allowfullscreen></iframe> </div>



                    </div>


                  </div>
                </el-tab-pane>
                <el-tab-pane label="EUR预测" name="second">
                  <div class="card">
                    <div class="back">


                      <div class="titleDesign">
                        <div style="display: flex">
                          <div class="Title1">
                            1. 数据来源：
                          </div>
                          <div class="Title2">
                            测、录井数据;&nbsp;
                            地震数据;&nbsp;
                            工程参数;&nbsp;
                            压裂实时采集数据&nbsp;
                          </div>
                        </div>
                        <div>
                          <div class="Title1">
                            2. 操作流程：<span class="Title2" >     选择【精简参数上传】、【完全参数上传】或者【参数反推】；在对应的功能页面下输入相关参数进行数据分析；如选择【精简参数】上传，在功能页面输入相关的地质参数和工程参数数据，点击【储量预测】按钮，即可查看数据分析结果。
                  </span>
                          </div>

                        </div>
                        <div style="display: flex">
                          <div class="Title1">
                            *&nbsp;  注意事项：
                          </div>
                          <div class="Title2">
                            参数的选择对数据分析的准确度至关重要，请输入正确的参数数据。   </div>
                        </div>



                      </div>
                      <div ref="iframeContainer" class="iframeStyle">
                        <iframe :src="iframeUrl2" frameborder="0" allowfullscreen></iframe>
                      </div>




                    </div>


                  </div>
                </el-tab-pane>
              </el-tabs>


            </div>

          </div>
          <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                   <netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel">
        <div class="screen1">
          <div class="topback">
            <headtip @showDialog="showPartner"></headtip>
            </div>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">基于压裂参数的EUR预测<br>及参数反推平台</div>
            <div class="title2">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div>
            <div class="title3">①&nbsp;工艺类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</div>
          </div>

          <div class="titlle3" @click="clickConsult('油气通')">
            产品咨询
          </div>
          <!-- <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div> -->
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>

        <div class="screenmake2">
          <div class="heighLight">   <span>功能介绍</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="imgDesign">  <img src="@/assets/frackIcon1.png"></div>
                  <div>
                    <div class="Title1">压裂相关性分析</div>
                    <div class="Title2">
                      利用先进的深度学习技术，对压裂EUR预测相关的工程参数及地质参数进行分析，准确分析不同参数对于最终可采储量的影响，从而为施工过程中的调整做出指导。

                    </div>
                  </div>

                </div>
                <div class="titleDesign" style="margin-top: 10px">
                  <div class="imgDesign">  <img src="@/assets/frackIcon2.png"></div>
                  <div>
                    <div class="Title1">EUR预测</div>
                    <div class="Title2">
                      通过机器学习技术，将对EUR造成影响的参数进行深度挖掘，得出不同参数和EUR之间的联系，进而为用户提供EUR分析。
                      其中，参数反推是利用机器学习技术，设定数值，根据测定的现场地质情况，选定任意1-6个工程参数进行反推参数数值。

                    </div>
                  </div>

                </div>
                <div class="titleDesign" style="margin-top: 10px">
                  <div class="imgDesign">  <img src="@/assets/frackIcon3.png"></div>
                  <div>
                    <div class="Title1">预测EUR历史记录</div>
                    <div class="Title2">
                      用户可查看已手动保存的数据分析结果，并根据任务场景、数据所属油田、所属区块、创建时间进行查询。

                    </div>
                  </div>

                </div>
              </div>


            </div>




          </div>

        </div>
        <div class="screenmake2" style="background: #D2E3FF !important;">
          <div class="heighLight">   <span>功能体验</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign" style="margin-top: 0">
                  <div class="Title2" style="margin-left: 0">
                    基于压裂参数的EUR预测及参数反推平台功能涵盖<span style="color:#FF6400;">压裂相关性分析、EUR预测、预测EUR历史记录</span>。<br>您可前往PC端进行免费功能体验！<br>体验链接（PC端）: &nbsp;<span style="border-bottom: #333333 1px solid">https://cn.ogdri.com/eur</span><br>
                    若您有合作或产品咨询需求，请<span style="color:red" @click="clickConsult('地质工程云平台GPTs')">联系我们</span>！
                  </div>

                </div>
              </div>


            </div>




          </div>

        </div>
        <div ref="bottom">
          <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>
import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";
import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {
  name: "",
  props: [],
  components: {commentFeedback,
    productFeedback,
    netFeedback,PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {      dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title:'',
      pageTitle:  '基于压裂参数的EUR预测及参数反推平台-油气藏综合地质评价服务-地质工程云平台GPT',
      pageDescription: '通过先进的数据分析技术，结合压裂参数，为您分析预计采收储量（EUR）。不仅如此，系统还能根据EUR值反向推算关键参数，实现历史记录的深度挖掘和相关性分析，帮助您优化油藏管理和提高采收效率。',
      pageKeywords: '基于压裂参数的EUR预测及参数反推平台,油气藏综合地质评价服务,地质工程云平台GPT',
      model:false,
      activeName: 'first',
      iframeUrl1:"https://ab.oilgasgpts.com/jyylcshdycjcshfy",
      iframeUrl2:"https://ab.oilgasgpts.com/jyylcshdycjcshfy?type=eur",
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/modeling4.png"),

        title3:"" +
            "<li>四川盆地CN地区煤层气地质工程一体化开发方案</li>" +
            "<li>金山气田地质工程一体化风险总包实施方案\t</li>" +
            "<li>长宁煤层气地质工程一体化开发方案</li>" +
            "<li>贵州六枝牛场区块煤矿瓦斯综合治理——地质工程一体化总包服务</li>"
      },
      ]
    }

  },


  methods: {    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime(){
      window.open("/realtime")
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle:  '基于压裂参数的EUR预测及参数反推平台-油气藏综合地质评价服务-地质工程云平台GPT',
        pageDescription: '整合安东全球工程区域地质信息，统一各区域数据体规则，形成安东一体化油藏地质数据资产，实现平台级油藏地质信息管理。借助机器学习、深度学习、大语言模型等技术，对海量油藏地质数据进行智能化处理和分析，对油藏地质情况进行准确、详细和交互式可视化的了解。',
        pageKeywords: '基于压裂参数的EUR预测及参数反推平台,油气藏综合地质评价服务,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 70vh;

      padding: 0 0 0 0;
      background-image: url("../../assets/datainnerbackground.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .topback {
        position: fixed;
        z-index: 9;
        width: 99vw;
        padding: 1vh  0;
        background-color: rgb(52, 48, 48,0.3);
      }
      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0 {
      padding: 6.8430160692vh 9vw 2.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .heighLight1 {
        text-align: left;
        margin: 0 auto;
        width: 65vw;
        height: fit-content;
        font-size: 1.2vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 4.5vh;
      }

      ::v-deep .el-tabs__nav {
        text-align: center;
        float: none;
      }

      ::v-deep .el-tabs__item {
        width: 50%;
        font-size: 1.2vw !important;
        padding: 2vh 5vh;
        height: fit-content;
      }

      ::v-deep .el-tabs__active-bar {
        background-color: #316fff;
      }

      ::v-deep .el-tabs__header {

        margin: 0 0 0 0;
      }

      ::v-deep .el-tabs__item.is-active {
        color: #316fff;
      }

      .grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          //padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            //display: flex;
            position: relative;
            //background-color: #ececec;
            padding: 0 0 0 0;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 85vh;;
            overflow: hidden;

            .iframeStyle {
              background-color: #ececec;
              margin: -18vh 0 0 -9.8vw;
              width: 100vw;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }

            .iframeStyle iframe {
              background-color: #ececec;
              width: 100vw;
              min-height: 200vh;
              height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }

            .imgDesign {

              width: fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 2vh 3vw;
              width: 100%;
              background-color: #ececec;
              border-top-left-radius: 0;
              border-top-right-radius: 9px;

              .Title1 {

                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 1.2vw;
              }

              .Title2 {
                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 1.2vw;
              }
            }
          }
        }

      }
    }

    .screenmake1 {
      padding: 6.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            position: relative;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 40vh;;
            padding: 0 0 3vh 0;
            overflow: hidden;

            .imgDesign {
              height: 8vh;
              width: 8vh;
              margin: 2vh auto;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              margin: 0 1vw;

              .Title1 {
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color: #111111;
                text-align: center;
                font-size: 1vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }

              .Title2 {
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                color: #111111;
                text-align: justify;
                font-size: 0.9vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4vh;
              }
            }
          }
        }

      }
    }
  }

}

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;


  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 400px;

    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/groundinnerbannerM.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .titlle3{
      position: absolute;
      bottom:50px;
      left:50%;
      transform: translateX(-60%);
      font-size: 17px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 10px 25px;
      height: 40px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }

    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 18px;
      font-size: 10px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      line-height: 14px;
    }

    .layer2 {
      // position: absolute;
      // left:0;
      margin-top:20px;
      .title1 {
        margin:0 auto;
        width: fit-content;
        text-align: center;
        height: fit-content;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: white;

        line-height:32px;
      }

      .title2 {
        margin: 20px auto 0 auto;
        width: 90%;
        text-align: left;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        line-height: 24px;
      }

      .title3 {
        margin: 15px auto;
        width: fit-content;
        text-align: left;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:white;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {

      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            border-radius: 9px;
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }


          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }

          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }

  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }

    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/frack1.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 220px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 30px;
              margin-top: -5px;
              height: 30px;
              opacity: 1;
            }

            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 10px 40px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }

            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;


    padding: 20px 15px;
    background: black;

    .heighLight {
      text-align: center;
      height: 21px;

      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }

    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:200px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;

        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
