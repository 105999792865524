import { render, staticRenderFns } from "./magnet.vue?vue&type=template&id=0a5d9258&scoped=true"
import script from "./magnet.vue?vue&type=script&lang=js"
export * from "./magnet.vue?vue&type=script&lang=js"
import style0 from "./magnet.vue?vue&type=style&index=0&id=0a5d9258&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a5d9258",
  null
  
)

export default component.exports