
<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <div class="topback">
            <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">光纤产剖/注剖监测技术
              </div>
              <div class="title2">光纤产剖/注剖监测技术是将钢丝/铠装光纤通过连续油管、油管等载体，或者依靠自重的方式下放至目的层位，从而实现光纤分布式监测。光纤产剖/注剖监测技术以光纤本身作为传感器，无需额外下入井下测井仪器，通过测量背向散射光，探测出井下光纤沿线的温度、声波等数据，通过时时迭代方式，对井内产出/注入状态进行分析，从而实现油气井各簇产出/注入剖面监测。</div>   <div class="titlle3" @click="clickConsult('地质工程云平台')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenGpts"  style="background-color: #fff;" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;光纤产剖/注剖监测技术
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back "  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw;width: 60%;">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px;height: 50vh;" src="@/assets/profilinginjection1.jpg"/>
                  </div>

                  <div class="titleDesign">
                    <div class="title1">适用范围：</div>
                    <div class="title2">适用于所有注入/产出井</div>
                    <div class="title1">特点优势：</div>
                    <div class="title2" >
                      <li>光纤本身就是传感器，无需仪器，安全性高</li>
                      <li>静止式测量，获取的数据更真实</li>
                      <li>分布式测量，传感器覆盖全井筒</li>
                      <li>长时间实时监测井中生产规律，真实反应注入、产出情况</li>
                      <li>可适用于低渗透、复杂井况的产出剖面测量</li>
                      <li>实现长水平段油气井产出剖面监测</li>
                      <li>精细解释每一段簇的详细产出情况</li>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="screenmake4">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">

                      <div style="background-color: white;width: 55%">
                        <div class="imgDesign">
                          <img loading="lazy" :src="item.img"/>
                        </div>
                      </div>

                      <div class="titleDesign">
                        <div class="title1" v-html="item.title1"></div>
                        <div class="title2" v-html="item.title2"></div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>

          <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                   <netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <div class="topback">
            <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">智能工程迭代优化GPTs</div>
              <div class="title2">依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。</div>
            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">

            <div class="grid">
              <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/groundarea.png"/>

            </div>

          </div>
          <div ref="bottom">
            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>

        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>
import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";
import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {commentFeedback,
    productFeedback,
    netFeedback,PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {      dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title:'',
      pageTitle: '光纤产剖/注剖监测技术-油气田开发动态监测GPTs-地质工程云平台GPT',
      pageDescription: '光纤产剖/注剖监测技术是将钢丝/铠装光纤通过连续油管、油管等载体，或者依靠自重的方式下放至目的层位，从而实现光纤分布式监测。光纤产剖/注剖监测技术以光纤本身作为传感器，无需额外下入井下测井仪器，通过测量背向散射光，探测出井下光纤沿线的温度、声波等数据，通过时时迭代方式，对井内产出/注入状态进行分析，从而实现油气井各簇产出/注入剖面监测。',
      pageKeywords: '光纤产剖/注剖监测技术,油气田开发动态监测GPTs,地质工程云平台GPT',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/profilinginjection2.png"),
        title1: '南堡气库连续油管光纤产剖监测：<br><br>',
        title2:"光纤产剖/注剖监测技术的应用已经取得了一定的成效，特别是在长庆气田、西南气田、冀东油田等区域，该技术有效提高了油气井的监测能力和生产效率，为油气田的开发提供了重要的技术支持。<br>",
        title3:"<br>" +
            "<li>监测制度：3800-3840m为三个生产制度下主要产出位置，10万方/天制度下产气占比83.84%；15万方/天制度下产气占比产气占比79.53%；20万方/天制度下产气占比41.75%；3664-3680m为20方/天制度下次要产出位置，产气占比24.47%</li>"
      },
      {
        img:require("@/assets/profilinginjection3.png"),
        title1: '南堡气库连续油管光纤产剖监测：<br><br>',
        title2:"光纤产剖/注剖监测技术的应用已经取得了一定的成效，特别是在长庆气田、西南气田、冀东油田等区域，该技术有效提高了油气井的监测能力和生产效率，为油气田的开发提供了重要的技术支持。<br>",
        title3:"<br>" +
            "<li>	结合DTS与DAS分析：3840m以下，在各制度下产出气贡献较低，结合DTS与DAS响应与井眼轨迹综合分析为井筒积液导致；3664m-3680m在大压差下产量较高，说明该储层启动压差高。</li>"
      },
      ]
    }

  },


  methods: {    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime(){
      window.open("/realtime")
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>

.totalmakeHole{
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 0 0 0 0;
      background-image: url("../../assets/guangxianchanpao.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      & > div{
        position: relative;
        z-index: 10;
      }
      &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.4));
      }

      .topback {
        position: fixed;
        z-index: 9;
        width: 99vw;
        padding: 1vh  0;
        background-color: rgb(52, 48, 48,0.3);
      }
      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            margin:auto;
            padding: 0 3vw;
            width: 50%;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4{
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:68vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              background-color: white;
              width: fit-content;
              height:100%;
              margin:0 auto;
              opacity: 1;
            }
            .titleDesign{
              margin:auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;
              .title1{
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }
              .title2{
                line-height: 3.5vh;
                font-size: 0.9vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:70vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }

    .screenmake0{
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:66.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width:fit-content;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin:0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;
              .title1{
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 1vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 450px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenmake0{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top:25px;

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:214px;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 340px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -68px -10px  ;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
