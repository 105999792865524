import { render, staticRenderFns } from "./offsetwell.vue?vue&type=template&id=071cc270&scoped=true"
import script from "./offsetwell.vue?vue&type=script&lang=js"
export * from "./offsetwell.vue?vue&type=script&lang=js"
import style0 from "./offsetwell.vue?vue&type=style&index=0&id=071cc270&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071cc270",
  null
  
)

export default component.exports