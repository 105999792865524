import { render, staticRenderFns } from "./earthquake.vue?vue&type=template&id=697a9666&scoped=true"
import script from "./earthquake.vue?vue&type=script&lang=js"
export * from "./earthquake.vue?vue&type=script&lang=js"
import style0 from "./earthquake.vue?vue&type=style&index=0&id=697a9666&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697a9666",
  null
  
)

export default component.exports